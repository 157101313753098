import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import React from 'react';
export default function CategoryList({ categories, page, pageChanged, mobileOpen }) {
    return (
        <List sx={{
            // selected and (selected + hover) states
            '&& .Mui-selected, && .Mui-selected:hover': {
                bgcolor: 'blue',
                '&, & .MuiListItemIcon-root': {
                    color: 'LightBlue',
                },
            },
            // hover states
            '& .MuiListItemButton-root:hover': {
                bgcolor: 'orange',
                '&, & .MuiListItemIcon-root': {
                    color: 'yellow',
                },
            },
        }}>
            {categories.map((category, index) => (
                <ListItem key={category.id}
                    disablePadding>
                    <ListItemButton
                        selected={page.category === index}
                        onClick={() => {
                            pageChanged(index, 0);
                            mobileOpen(false);
                        }}>
                        <ListItemIcon>
                            <FactCheckIcon />
                        </ListItemIcon>
                        <ListItemText primary={category.title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
}