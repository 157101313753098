import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import ActivityRating from './ActivityRating';
import { useAuthContext } from '../../hooks/useAuthContext';

export default function AnswerForm({ question, list, toYou, categoryTitle }) {
    const { user } = useAuthContext();
    const [tried, setTried] = useState('');
    const [rating, setRating] = useState(-1);
    const [essential, setEssential] = useState(false);
    const [curious, setCurious] = useState(false);
    const [softLimit, setSoftLimit] = useState(false);
    const [hardLimit, setHardLimit] = useState(false);

    // Load the data 
    useEffect(() => {
        if (question && list) {
            const fetchData = async () => {
                const ref = doc(db, "answers", list + "-" + question.id + "-" + toYou);
                const answerSnap = await getDoc(ref);
                if (answerSnap.exists()) {
                    const answer = answerSnap.data();
                    if (answer.tried !== undefined) {
                        if (answer.tried === true) {
                            setTried('yes');
                        } else {
                            setTried('no');
                        }
                    } else {
                        setTried('');
                    }
                    if (answer.rating !== undefined) {
                        setRating(answer.rating);
                    } else {
                        setRating(-1);
                    }
                    if (answer.essential !== undefined) {
                        setEssential(answer.essential);
                    } else {
                        setEssential(false);
                    }
                    if (answer.curious !== undefined) {
                        setCurious(answer.curious);
                    } else {
                        setCurious(false);
                    }
                    if (answer.softLimit !== undefined) {
                        setSoftLimit(answer.softLimit);
                    } else {
                        setSoftLimit(false);
                    }
                    if (answer.hardLimit !== undefined) {
                        setHardLimit(answer.hardLimit);
                    } else {
                        setHardLimit(false);
                    }
                } else {
                    // This is new, reset everything
                    setTried('');
                    setRating(-1);
                    setEssential(false);
                    setCurious(false);
                    setSoftLimit(false);
                    setHardLimit(false);
                }
            }
            fetchData();
        }
    }, [question, list, toYou]);

    const getAnswerRef = () => {
        return doc(db, "answers", list + "-" + question.id + "-" + toYou);
    }

    const getAnswerConstants = () => {
        return { uid: user.uid, list: list, question: question.question, category: categoryTitle, toYou: toYou }
    }

    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "yes") return true;
        }
        return false;
    }

    const saveTried = async (newTried) => {
        const ref = getAnswerRef();
        await setDoc(ref, { tried: str2bool(newTried), ...getAnswerConstants() }, { merge: true });
        setTried(newTried);
    };

    const saveRating = async (newRating) => {
        const ref = getAnswerRef();
        await setDoc(ref, { rating: newRating, ...getAnswerConstants() }, { merge: true });
        setRating(newRating);
    };

    const saveEssential = async (newEssential) => {
        const ref = getAnswerRef();
        await setDoc(ref, { essential: newEssential, ...getAnswerConstants() }, { merge: true });
        setEssential(newEssential);
    };

    const saveCurious = async (newCurious) => {
        const ref = getAnswerRef();
        await setDoc(ref, { curious: newCurious, ...getAnswerConstants() }, { merge: true });
        setCurious(newCurious);
    };

    const saveSoftLimit = async (newSoftLimit) => {
        const ref = getAnswerRef();
        await setDoc(ref, { softLimit: newSoftLimit, ...getAnswerConstants() }, { merge: true });
        setSoftLimit(newSoftLimit);
    };

    const saveHardLimit = async (newHardLimit) => {
        const ref = getAnswerRef();
        await setDoc(ref, { hardLimit: newHardLimit, ...getAnswerConstants() }, { merge: true });
        setHardLimit(newHardLimit);
    };

    return (
        <Card sx={{ maxWidth: 340 }}  variant='outlined' style={{ backgroundColor: "#484D52", elevation: "8" }}>
            <CardContent>
                <Stack spacing={1}>
                    {toYou ?
                        <Typography variant="h5" noWrap align="center">
                            Done to/for you
                        </Typography>
                        :
                        <Typography variant="h5" noWrap align="center">
                            Done to/for others
                        </Typography>
                    }
                    <Stack direction="row" spacing={1} alignItems='center'>
                        <Typography variant="h6" sx={{ pr: 1 }}>Tried?</Typography>
                        <RadioGroup
                            row
                            name="to-you-tried-radio-buttons-group"
                            value={tried}
                            onChange={(e) => {
                                saveTried(e.target.value);
                            }}
                        >
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        </RadioGroup>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="h6">Interest</Typography>
                        <ActivityRating value={rating} setValue={(newValue) => {
                            saveRating(newValue);
                        }} />
                    </Stack>
                    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                        <FormControlLabel
                            control={<Checkbox
                                checked={essential}
                                onChange={(e) => {
                                    saveEssential(e.target.checked);
                                }} />}
                            label="Essential" />
                        <FormControlLabel
                            control={<Checkbox
                                checked={curious}
                                onChange={(e) => {
                                    saveCurious(e.target.checked);
                                }} />}
                            label="Curious" />
                        <FormControlLabel
                            control={<Checkbox
                                checked={softLimit}
                                onChange={(e) => {
                                    saveSoftLimit(e.target.checked);
                                }} />}
                            label="Soft Limit" />
                        <FormControlLabel
                            control={<Checkbox
                                checked={hardLimit}
                                onChange={(e) => {
                                    saveHardLimit(e.target.checked);
                                }} />}
                            label="Hard Limit" />
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}