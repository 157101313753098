import { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useLogin } from '../../hooks/useLogin'

export default function SendReset() {
  const { error, isPending, sendPasswordReset } = useLogin()
  const [isSent, setIsSent] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    sendPasswordReset(data.get('email'))
    setIsSent(true)
  };

  // TODO - check the logic and timing - 
  useEffect(() => {
    if (!isPending && isSent) {
      if (error) {
        setIsSent(false)
      }
    }
  }, [error, isPending, isSent])

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        {(!isPending && isSent) &&
          <Typography component="h1" variant="h5">
            Email sent, check your email and follow the instructions
          </Typography>
        }
        {(isPending || !isSent) &&
          <div>
            <Typography component="h1" variant="h5">
              Send password reset email
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {!isPending &&
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Send
                </Button>
              }
              {isPending &&
                <Button
                  disabled
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Sending...
                </Button>
              }
              {error &&
                <Alert severity="error">
                  {error}
                </Alert>
              }
            </Box>
          </div>
        }
      </Box>
    </Container>
  );
}

