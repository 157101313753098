import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup';
import Radio from '@mui/material/Radio/Radio';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import { useLogin } from '../../hooks/useLogin'

export default function Landing() {
    const { isPending, loginAnonymously } = useLogin()
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const method = data.get('radio-buttons-group');
        switch (method) {
            case 'anonymous':
                loginAnonymously();
                break;
            case 'login':
                navigate('/login');
                break;
            case 'signup':
                navigate('/signup');
                break;
            default:
                console.error("Unknown selection: " + method);
                break;
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h2" variant="h5">
                    Use anonymously or create a free account to store your list(s)
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <RadioGroup
                        defaultValue="anonymous"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="anonymous" control={<Radio />} label="Use anonymously" />
                        <FormControlLabel value="signup" control={<Radio />} label="Create an account" />
                        <FormControlLabel value="login" control={<Radio />} label="I already have an account, log in" />
                    </RadioGroup>
                    {!isPending &&
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<NavigateNext />}
                            sx={{ mt: 2 }}
                        >
                            Next
                        </Button>
                    }
                    {isPending &&
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<NavigateNext />}
                            sx={{ mt: 2 }}
                            disabled
                        >
                            Loading...
                        </Button>
                    }
                </Box>
            </Box>
        </Container>
    );
}

