import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCollection } from "../../hooks/useCollection";
import Question from "./Question";

export default function List() {
  const { id } = useParams()

  // When the categories and questions are loaded we can render the question view
  const { documents: categories } = useCollection('categories');
  const { documents: questions } = useCollection('questions');
  const [questionMap, setQuestionMap] = useState();

  // If the questions are loaded, map from their categories to an array of
  // questions for each category
  useEffect(() => {
    if (questions) {
      const newQuestionMap = new Map();
      questions.forEach(question => {
        if (newQuestionMap[question.category] === undefined) {
          newQuestionMap[question.category] = [];
        }
        newQuestionMap[question.category].push(question);
      });
      setQuestionMap(newQuestionMap);
    }
  }, [questions])

  return (
    <div>
      {categories && questionMap && <Question categories={categories} questions={questionMap} list={id} />}
      {(!categories || !questionMap) && <div>Loading...</div>}
    </div>
  )
};