// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Timestamp } from 'firebase/firestore'
import { GoogleAuthProvider, initializeAuth, debugErrorMap, browserSessionPersistence, browserPopupRedirectResolver } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDO7PoeZSPHp2_4Z629HNmJ59lXUjb0H2s",
    authDomain: "yesnomaybe-8217e.firebaseapp.com",
    projectId: "yesnomaybe-8217e",
    storageBucket: "yesnomaybe-8217e.appspot.com",
    messagingSenderId: "252494898110",
    appId: "1:252494898110:web:5b92fa5959e08dcaf0cfef",
    measurementId: "G-1Y0J63721T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// TODO - what do I want to do with analytics
const analytics = getAnalytics(app);

// init firestore
const db = getFirestore()

// init firebase auth
// The persistence and popupRedirect resolver were necessary for the
// google pop-up login since I'd overriden the error map
const auth = initializeAuth(app, {
    errorMap: debugErrorMap, 
    persistence: browserSessionPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
})

// User friendly firebase auth messages
const getFirebaseAuthErrorMessage = (errorMesssage) => {
    return errorMesssage
        .replace('Firebase: ', '')
        .replace(/\(auth.*\)\.?/, '');
}

// TODO - maybe take this out?  In case I want sign in with Google
const googleProvider = new GoogleAuthProvider();

export { db, auth, analytics, googleProvider, getFirebaseAuthErrorMessage, Timestamp }