import React from 'react';
import { useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useFirestore } from '../../hooks/useFirestore'

export default function ListOfLists({ lists }) {
    const { deleteDocument } = useFirestore('lists')
    const [open, setOpen] = useState(false)

    const handleDelete = (id) => {
        deleteDocument(id)
    }

    const handleShare = (id) => {
        setOpen(true)
        const ahref = window.location.href;
        navigator.clipboard.writeText(ahref + 'summary/' + id);
    };

    return (
        <Box component="fieldset" sx={{ bgcolor: 'background.paper', width: 400 }}>
            <legend>Your lists</legend>
            {lists.map((list) => (
                <div key={list.id}>
                    <Card sx={{ maxWidth: 345, m: 1 }} variant='outlined'>
                        <CardActionArea href={`/list/${list.id}`}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    <FactCheckIcon color="primary" sx={{ mr: 1 }} />
                                    {list.createdAt.toDate().toDateString()}
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {list.title}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button href={`/list/${list.id}`}>Edit</Button>
                            <>
                                <Button onClick={() => handleShare(list.id)}> Share</Button>
                                <Snackbar
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    autoHideDuration={2000}
                                    message="Copied to clipboard"
                                />
                            </>
                            <Button href={`/summary/${list.id}`}>Summary</Button>
                            <Button onClick={() => handleDelete(list.id)}>Delete</Button>
                        </CardActions>
                    </Card>
                </div>
            ))}
        </Box >
    );
}

