import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// pages & components
import Navbar from './components/Navbar'
import AgeCheck from './components/AgeCheck'
import Copyright from './components/Copyright'
import Lists from './pages/lists/Lists'
import List from './pages/list/List'
import Summary from './pages/summary/Summary'
import Landing from './pages/landing/Landing'
import Login from './pages/auth/Login'
import Signup from './pages/auth/Signup'
import SendReset from './pages/auth/SendReset'
import Auth from './pages/auth/Auth'

function App() {
  const { user, authIsReady } = useAuthContext()
  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={user ? <Lists /> : <Landing />}
            />
            <Route
              path="/list/:id"
              element={user ? <List /> : <Navigate to="/" />}
            />
            <Route
              path="/summary/:id"
              // TODO - do I want people to have to log in?
              // TODO - this doesn't work anyway because of auth is ready?
              element={<Summary />}
            />
            <Route
              path="/send-reset"
              element={!user ? <SendReset /> : <Navigate to="/" />}
            />
            <Route
              path="/auth"
              element={!user ? <Auth /> : <Navigate to="/" />}
            />
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="/signup"
              element={!user ? <Signup /> : <Navigate to="/" />}
            />
          </Routes>
        </BrowserRouter>
      )}
      <AgeCheck sx={{ mt: 5 }} />
      <Copyright />
    </div>

  );
}

export default App;
