import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function Pagination({ categories, questions, page, prevPage, nextPage, prevCategory, nextCategory }) {
    const categoryId = categories[page.category].id;
    const categoryPageCount = questions[categoryId].length;
    const canGoBack = page.page >= 1 || page.category >= 1;
    const canGoNext = page.page < categoryPageCount - 1 || page.category < categories.length - 1;
    const canGoBackCategory = page.category >= 1;
    const canGoNextCategory = page.category < categories.length - 1;

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ display: 'flex', mt: 1 }} >
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
                    variant="outlined"
                    disabled={!canGoBack}
                    startIcon={<ChevronLeftIcon />}
                    sx={{ display: "flex", justifyContent: "center", mr: 1 }}
                    onClick={() => {
                        prevPage();
                    }}>

                    Prev
                </Button>
                <Button
                    autoFocus
                    disabled={!canGoNext}
                    variant="outlined"
                    endIcon={<ChevronRightIcon />}
                    sx={{ display: "flex", justifyContent: "center", ml: 1 }}
                    onClick={() => {
                        nextPage();
                    }}
                >
                    Next
                </Button>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Button
                    variant="outlined"
                    disabled={!canGoBackCategory}
                    startIcon={<ChevronLeftIcon />}
                    sx={{ display: "flex", justifyContent: "center", mr: 3 }}
                    style={{minWidth: '200px'}}
                    onClick={() => {
                        prevCategory();
                    }}>
                    {canGoBackCategory && categories[page.category - 1].title}
                </Button>
                <Button
                    disabled={!canGoNextCategory}
                    variant="outlined"
                    endIcon={<ChevronRightIcon />}
                    sx={{ display: "flex", justifyContent: "center", ml: 3 }}
                    style={{minWidth: '200px'}}
                    onClick={() => {
                        nextCategory();
                    }}
                >
                    {canGoNextCategory && categories[page.category + 1].title}
                </Button>
            </Grid>
        </Grid>
    );
}