import { useEffect, useState } from 'react'
import { auth, getFirebaseAuthErrorMessage } from '../firebase/config'
import { signOut  } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const logout = async () => {
        setError(null)
        setIsPending(true)

        try {
            // sign the user out
            signOut(auth)

            // dispatch logout action
            dispatch({ type: 'LOGOUT' })

            // update state
            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { logout, error, isPending }
}