import { useReducer, useEffect, useState } from "react"
import { db, Timestamp } from '../firebase/config'
import { collection, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null,
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null }
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null }
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null }
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload }
        case "UPDATED_DOCUMENT":
            return { isPending: false, document: action.payload, success: true, error: null }
        default:
            return state
    }
}

export const useFirestore = (col) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(false)

    // collection ref
    const ref = collection(db, col)

    // only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // add a document
    const addDocument = async (doc) => {
        dispatch({ type: 'IS_PENDING' })
        const createdAt = Timestamp.fromDate(new Date())
        await addDoc(ref, { ...doc, createdAt })
            .then(addedDocument => { 
                dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument }) 
            })
            .catch(err => { 
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.message }) 
            })
    }

    // delete a document
    const deleteDocument = async (id) => {
        dispatch({ type: 'IS_PENDING' })
        const docRef = doc(db, col, id)
        await deleteDoc(docRef)
            .then(() => { 
                dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' })
            })
            .catch(err => { 
                dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not delete:' + err.message }) 
            })
    }

    // update a document
    const updateDocument = async (id, updates) => {
        dispatch({ type: "IS_PENDING" })
        const docRef = doc(db, col, id)
        await updateDoc(docRef, updates)
            .then(updatedDocument => {
                dispatchIfNotCancelled({ type: "UPDATED_DOCUMENT", payload: updatedDocument })
                return updatedDocument
            })
            .catch(err => {
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
                return null
            })
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { addDocument, deleteDocument, updateDocument, response }

}