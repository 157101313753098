import { useState } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AnswerForm from './AnswerForm';
import CategoryList from './CategoryList';
import Pagination from './Pagination';
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from "../../hooks/useAuthContext"

import { useSwipeable } from 'react-swipeable';
import { Link } from 'react-router-dom'
import logo from '../../images/trisquel.png';

const drawerWidth = 220;

export default function Question({ categories, questions, list, window }) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const { logout, isPending: isLogoutPending } = useLogout();
    const { user } = useAuthContext();

    // The question is a function of the category and the page
    const [page, setPage] = useState({ category: 0, page: 0, question: questions[categories[0].id][0] });

    const prevPage = () => {
        if (page.page >= 1) {
            onPageChanged(page.category, page.page - 1);
        } else if (page.category >= 1) {
            onPageChanged(page.category - 1, questions[categories[page.category - 1].id].length - 1);
        }
    }

    const nextPage = () => {
        const categoryId = categories[page.category].id;
        const categoryPageCount = questions[categoryId].length;
        if (page.page < categoryPageCount - 1) {
            onPageChanged(page.category, page.page + 1);
        } else if (page.category < categories.length - 1) {
            onPageChanged(page.category + 1, 0);
        }
    }

    const prevCategory = () => {
        if (page.category >= 1) {
            onPageChanged(page.category - 1, 0);
        }
    }

    const nextCategory = () => {
        if (page.category < categories.length - 1) {
            onPageChanged(page.category + 1, 0);
        }
    }

    const onPageChanged = (c, p) => {
        setPage({ category: c, page: p, question: questions[categories[c].id][p] });
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => nextPage(),
        onSwipedRight: () => prevPage()
    });

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            nextPage();
        }
        if (event.key === 'ArrowLeft') {
            prevPage();
        }
        if (event.key === 'ArrowRight') {
            nextPage();
        }
    }

    const drawer = (
        <div>
            <Toolbar>Categories</Toolbar>
            <Divider />
            <CategoryList categories={categories} page={page} pageChanged={onPageChanged} mobileOpen={setMobileOpen} />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        component="img"
                        sx={{
                            height: 24,
                            width: 24,
                        }}
                        alt="Logo"
                        src={logo}
                    />
                    <Typography color="textPrimary" to="/" variant="h6" component={Link} sx={{ flexGrow: 1, pl: 1, textDecoration: 'none' }}>
                        Yes/No/Maybe?
                    </Typography>
                    {user && <Button component={Link} to="/" color="inherit">
                        Lists
                    </Button>}
                    {(!isLogoutPending && user) && <Button color="inherit" onClick={logout}>Logout</Button>}
                    {(isLogoutPending && user) && <Button color="inherit" disabled>Logging out...</Button>}
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="categories"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                {...swipeHandlers}
                onKeyUp={handleKeyUp}
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                {page &&
                    <>
                        <Typography paragraph variant="h6" color="text.primary" align='left'>{categories[page.category].title} ({page.page + 1}/{questions[categories[page.category].id].length})</Typography>
                        <Stack spacing={1} alignItems='center'>
                            <Typography variant="h4" align='center' color="text.primary" sx={{ pb: 2 }}>
                                {page.question.question}
                            </Typography>
                            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                <AnswerForm list={list} question={page.question} categoryTitle={categories[page.category].title} toYou={true} />
                                <AnswerForm list={list} question={page.question} categoryTitle={categories[page.category].title} toYou={false} />
                            </Stack>
                            <Pagination
                                categories={categories}
                                questions={questions}
                                page={page}
                                prevPage={prevPage}
                                nextPage={nextPage}
                                prevCategory={prevCategory}
                                nextCategory={nextCategory} />
                        </Stack>
                    </>
                }
            </Box>
        </Box >
    );
}

Question.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};