import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import ListOfLists from "./ListOfLists";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCollection } from '../../hooks/useCollection';
import { useFirestore } from '../../hooks/useFirestore'
import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function Lists() {
    const { user } = useAuthContext();
    const { documents: lists } = useCollection('lists', ["uid", "==", user.uid]);
    const { addDocument, response } = useFirestore('lists');
    const navigate = useNavigate();

    // form field values
    const [title, setTitle] = useState('My List');

    // Handle the user creating a new list
    const handleSubmit = async (e) => {
        e.preventDefault()
        await addDocument({
            title: title,
            uid: user.uid
        })
    }

    // If the user successfully creates a new list - navigate to the list UI
    // TODO - it gets real weird if I log out of the list one - need to clear the URL?
    useEffect(() => {
        if (response.success && response.document) {
            navigate(`/list/${response.document.id}`)
        }
    }, [response, navigate])

    return (
        <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box component="form" onSubmit={handleSubmit} noValidate alignItems="center" style={{ display: "flex", alignItems: "center" }} sx={{ pr: 1, pl: 1 }}>
                <Typography variant="h6">Create: </Typography>
                <TextField
                    margin="normal"
                    name="title"
                    label="title"
                    id="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    sx={{ pr: 1, pl: 1 }}
                />
                {!response.isPending &&
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        Go!
                    </Button>
                }
                {response.isPending &&
                    <Button
                        disabled
                        type="submit"
                        variant="contained"
                    >
                        loading...
                    </Button>
                }
                {response.error &&
                    <Alert severity="error">
                        {response.error}
                    </Alert>
                }
            </Box>
            {lists && lists.length > 0 && <ListOfLists lists={lists} />}
        </Box>
    );
}

