import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPremium, GridToolbar, gridFilteredSortedRowIdsSelector, gridRowTreeSelector } from '@mui/x-data-grid-premium';
import { useCollection } from '../../hooks/useCollection';
import { useParams } from 'react-router-dom'

const columns = [
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    editable: false,
  },
  {
    field: 'question',
    headerName: 'Activity',
    width: 150,
    editable: false,
  },
  {
    field: 'toYou',
    headerName: 'To',
    width: 100,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      } else if (params.value) {
        return 'You';
      } else {
        return 'Others';
      }
    },
  },
  {
    field: 'tried',
    headerName: 'Tried',
    width: 100,
    editable: false,
    type: 'boolean',
  },
  {
    field: 'rating',
    headerName: 'Interest',
    width: 100,
    editable: false,
    type: 'number',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      } else if (params.value === 1) {
        return 'Hate';
      } else if (params.value === 2) {
        return 'Dislike';
      } else if (params.value === 3) {
        return 'OK';
      } else if (params.value === 4) {
        return 'Like';
      } else if (params.value === 5) {
        return 'Love';
      }
    },
  },
  {
    field: 'essential',
    headerName: 'Essential',
    width: 100,
    editable: false,
    type: 'boolean',
  },
  {
    field: 'curious',
    headerName: 'Curious',
    width: 100,
    editable: false,
    type: 'boolean',
  },
  {
    field: 'hardLimit',
    headerName: 'Hard Limit',
    width: 100,
    editable: false,
    type: 'boolean',
  },
  {
    field: 'softLimit',
    headerName: 'Soft Limit',
    width: 100,
    editable: false,
    type: 'boolean',
  },
];

const getRowsWithoutGroups = ({ apiRef }) => {
  const rows = gridFilteredSortedRowIdsSelector(apiRef);
  const tree = gridRowTreeSelector(apiRef);

  return rows.filter((rowId) => tree[rowId].type !== 'group');
};

// TODO - make it so following a link past login still takes you here
export default function Summary() {
  const { id } = useParams()
  const { documents: answers } = useCollection('answers', ["list", "==", id]);
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {answers &&
        <DataGridPremium
          rows={answers}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 200,
              },
            },
            rowGrouping: {
              model: ['category'],
            },
          }}
          pageSizeOptions={[200]}
          slots={{ toolbar: GridToolbar }} 
          slotProps={{ toolbar: { csvOptions: { allColumns: true, getRowsToExport : getRowsWithoutGroups, includeColumnGroupsHeaders: false } } }}
        />}
    </Box>
  );
}