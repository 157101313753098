import { useState, useEffect } from 'react'
import { useAuthContext } from './useAuthContext'

// firebase imports 
import { auth, getFirebaseAuthErrorMessage } from '../firebase/config'
import { createUserWithEmailAndPassword } from 'firebase/auth'

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const signup = async (email, password) => {
        try {
            // signup
            const res = await createUserWithEmailAndPassword(auth, email, password)

            if (!res) {
                throw new Error('Could not complete signup')
            }

            // TODO - add some unique display name that the user can set if they 
            // want to make it easy to find/share results

            // TODO ?
            // // create a user document
            // await projectFirestore.collection('users').doc(res.user.uid).set({
            //     online: true,
            //     displayName,
            //     photoURL: imgUrl,
            // })

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { error, isPending, signup }
}