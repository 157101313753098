import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating'
import Box from '@mui/material/Box'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

export default function ActivityRating({ value, setValue }) {
    const [hover, setHover] = useState(-1);

    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
            color: theme.palette.action.disabled,
        },
    }));

    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
            label: 'Hate',
        },
        2: {
            icon: <SentimentDissatisfiedIcon color="error" fontSize="large" />,
            label: 'Dislike',
        },
        3: {
            icon: <SentimentSatisfiedIcon color="warning" fontSize="large" />,
            label: 'OK',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
            label: 'Like',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
            label: 'Love',
        },
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    IconContainer.propTypes = {
        value: PropTypes.number.isRequired,
    };

    return (
        <Box
            sx={{
                width: 200,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <StyledRating
                name="tried-to-you-rating"
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly
                value={value}
                onChange={(_event, newValue) => {
                    setValue(newValue);
                }}
                size="large"
                onChangeActive={(_event, newHover) => {
                    setHover(newHover);
                  }}
            />
            {((hover > 0) || (value > 0)) && (
                <Box sx={{ ml: 2 }}>{customIcons[hover !== -1 ? hover : value].label}</Box>
            )}
        </Box>
    )
}