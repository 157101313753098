import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from "../hooks/useAuthContext"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../images/trisquel.png';

export default function Navbar() {
    const { logout, isPending } = useLogout()
    const { user } = useAuthContext()
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Box
                        component="img"
                        sx={{
                            height: 24,
                            width: 24,
                        }}
                        alt="Logo"
                        src={logo}
                    />
                    <Typography color="textPrimary" to="/" variant="h6" component={Link} sx={{ flexGrow: 1, pl: 1, textDecoration: 'none' }}>
                        Yes/No/Maybe?
                    </Typography>
                    {user && <Button component={Link} to="/" color="inherit">
                        Lists
                    </Button>}
                    {!user && <Button component={Link} to="/login" color="inherit">
                        Login
                    </Button>}
                    {!user && <Button component={Link} to="/signup" color="inherit">
                        Signup
                    </Button>}
                    {(!isPending && user) && <Button color="inherit" onClick={logout}>Logout</Button>}
                    {(isPending && user) && <Button color="inherit" disabled>Logging out...</Button>}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

