import { useState, useEffect } from 'react'
import { auth, googleProvider, getFirebaseAuthErrorMessage } from '../firebase/config'
import {
    signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, confirmPasswordReset, signInAnonymously, verifyPasswordResetCode

} from 'firebase/auth'
import { useAuthContext } from './useAuthContext'

export const useLogin = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    // TODO - do I want to authentiacate emails with messages?  
    // TODO - I guess to prevent spamming people?
    const loginWithEmailAndPassword = async (email, password) => {
        setError(null)
        setIsPending(true)

        try {
            // login
            const res = await signInWithEmailAndPassword(auth, email, password)

            // update online status
            // const documentRef = projectFirestore.collection('users').doc(res.user.uid)
            // await documentRef.update({ online: true })

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                console.log(err)
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }

    const loginAnonymously = async () => {
        setError(null)
        setIsPending(true)

        try {
            // login
            const res = await signInAnonymously(auth)

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }

    const loginWithGoogle = async () => {
        setError(null)
        setIsPending(true)

        try {
            // login
            const res = await signInWithPopup(auth, googleProvider)

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                console.log(err)
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }

    const sendPasswordReset = async (email) => {
        setError(null)
        setIsPending(true)

        try {
            // send password reset email
            await sendPasswordResetEmail(auth, email)
            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                console.log(err)
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }


    const confirmReset = async (oobCode, newPassword) => {
        setError(null)
        setIsPending(true)

        try {
            // Verify that reset code
            const email = await verifyPasswordResetCode(auth, oobCode);

            // Reset the user's password
            await confirmPasswordReset(auth, oobCode, newPassword);

            // Log the user in
            const res = await signInWithEmailAndPassword(auth, email, newPassword)

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                console.log(err)
                setError(getFirebaseAuthErrorMessage(err.message))
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { error, isPending, loginWithEmailAndPassword, loginAnonymously, loginWithGoogle, sendPasswordReset, confirmReset }
}